<template>
  <div>
    <template v-if="$store.getters.getInvoices.length > 0 && $store.getters.getInvoicesLoading !== true">
      <div class="site-table-wrap invoices-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'invoices_ID',
          'invoices_Date',
          'invoices_Consolidation',
          'invoices_trackNumber',
          'invoices_Payment',
          'invoices_Processing',
          'invoices_Shipping',
          'invoices_Packaging',
          'invoices_Insurance',
          'invoices_Order',
          'invoices_VatAmount',
          'invoices_Total',
          'common_manage',
        ])"></div>

        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t('invoices_ID.localization_value.value') }}</th>
              <th>{{ $t('invoices_Date.localization_value.value') }}</th>
              <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
              <th>{{ $t('invoices_Consolidation.localization_value.value') }}</th>
              <th>{{ $t('invoices_Payment.localization_value.value') }}</th>
              <th>{{ $t('invoices_trackNumber.localization_value.value') }}</th>
              <th>{{ $t('invoices_Processing.localization_value.value') }}</th>
              <th>{{ $t('invoices_Shipping.localization_value.value') }}</th>
              <!--<th>{{ $t('invoices_Packaging.localization_value.value') }}</th>-->
              <th class="text-right">{{ $t('invoices_Packaging.localization_value.value') }}</th>
              <!--<th>{{ $t('invoices_Order.localization_value.value') }}</th>-->
              <th>{{ $t('invoices_VatAmount.localization_value.value') }}</th>
              <th>{{ $t('invoices_Total.localization_value.value') }}</th>
              <th>{{ $t('common_manage.localization_value.value')}}</th>
            </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getInvoices" :key="index">
            <td>

              <DefaultCheckbox
                      class="empty-label"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </td>
            <td>
              <span class="table-link btn-style"
                    @click="showInvoice(item.id)"
              >
                #{{item.id}}
              </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>
            <td>
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
												getConsolidationType(item.invoice_instance.instance) + '/' + item.invoice_instance.instance.id"
                >
                  {{item.invoice_instance.instance.id}}
                </router-link>

              </template>
            </td>
            <td>
              <div class="d-flex align-items-center" >

                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>

              </div>
            </td>
            <td>
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <TooltipTextHelper
                    :fullTextWidth="150"
                    :text="item.invoice_instance.instance.user_tracking_number"
                    :paragraph="item.invoice_instance.instance.user_tracking_number"
                />
              </template>
            </td>
<!--            <td>-->
<!--              <div class="d-flex align-items-center mb-1"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                  'common_Paid',-->
<!--                  'invoices_AccountBalance',-->
<!--                ])"></div>-->
<!--                <v-popover-->
<!--                        class="site-tooltip"-->
<!--                        offset="5"-->
<!--                        placement="top"-->
<!--                        trigger="hover"-->
<!--                >-->
<!--                  <StatusIcoBtn :type="'active'" class="mr-1"/>-->
<!--                  <template slot="popover">-->
<!--                    <div class="status-tooltip">-->
<!--                      <StatusIcoBtn :type="'active'" class="mr-1"/>-->
<!--                      {{$t('common_Paid.localization_value.value')}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-popover>-->
<!--                {{$t('invoices_AccountBalance.localization_value.value')}}-->
<!--              </div>-->
<!--            </td>-->
            <td class="text-right">
              <ValueHelper
                  v-if="+item.fee_amount > 0"
                  :value="item.fee_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </td>
            <td class="text-right">
              <ValueHelper
                  v-if="+item.net_cost_amount > 0"
                  :value="item.net_cost_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </td>
            <!--<td class="text-right">-->
            <!--<ValueHelper-->
            <!--:value="item.packaging_amount"-->
            <!--:replaceValue="0"-->
            <!--/>-->
            <!--</td>-->
            <td class="text-right">
              <ValueHelper
                  v-if="+item.packaging_amount > 0"
                  :value="item"
                  :deep="'packaging_amount'"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </td>
            <!--<td class="text-right">-->
            <!--456 (static)-->
            <!--</td>-->
            <td class="text-right">
              <ValueHelper
                  v-if="+item.vat_amount > 0"
                  :value="item.vat_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </td>
            <td class="text-right">
              <ValueHelper
                  v-if="+item.total_amount > 0"
                  :value="item.total_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </td>

            <td width="15%">
              <div class="table-right table-manage-list">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit"
                       @click="editTranslate([
                    'mypayments_printInvoice',
                  ])"></div>
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                    <template slot="item">
                      <LinkButton
                          :value="$t('mypayments_printInvoice.localization_value.value')"
                          :type="'print'"
                          :datePicker="true"
                          @changeDate="changeDate"
                          @datePickerBtn="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                          :popup-class="'mobile-center'"
                          :datePickerRange="false"
                          :datePickerTxt="$t('invoices_print.localization_value.value')"
                          :datePickerFormat="'YYYY-MM-DD'"
                      />
                      <LinkButton
                          v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
                          :value="$t(`invoices_refund.localization_value.value`)"
                          :type="'return'"
                          @click.native="$emit('refundInvoice', item.id)"
                      />
                      <LinkButton
                          v-if="isAdmin"
                          :value="$t(`common_edit.localization_value.value`)"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.financeInvoices + '/edit/' + item.id"
                      />
                    </template>
                  </ManagerButton>
                </div>

              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getInvoices" :key="index"
            >
              <InvoicesConsolidationsTableMobile
                      :item="item"
                      :selectedNow="selectedNow"
                      @showInvoice="showInvoice(item.id)"
                      @changeDate="(date) => changeDate(date)"
                      @printInvoice="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                      @refundInvoice="(data) => $emit('refundInvoice', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getInvoicesCommonList.next_page_url !== null && $store.getters.getInvoices.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextInvoicesPage}"
                    :count="$store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page < $store.getters.getInvoicesForPage ?
                  $store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page:
                  $store.getters.getInvoicesForPage"
            />
            <ExportBtn
                :customType="_.has($store.getters.getInvoicesCommonList, 'addition_export') ? $store.getters.getInvoicesCommonList.addition_export : null"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getInvoicesLoading === false && $store.getters.getInvoices.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <InfoPopup
            v-if="showPopup"
            :typeInvoice="'consolidation'"
            @close="toggleShowPopup(false)"
    />

  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore"
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationMixin} from "../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import InfoPopup from "../../../../../popups/InfoPopup/InfoPopup";
  import InvoicesConsolidationsTableMobile from "./InvoicesConsolidationsTableMobile/InvoicesConsolidationsTableMobile";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../staticData/staticVariables";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ExportBtn from '../../../../../../../coreComponents/Buttons/Export/Export'

  export default {
    name: "InvoicesConsolidationsTable",
    components: {
      StatusIcoBtn,
      TooltipTextHelper,
      ManagerButton,
      TableUserColumn,
      InvoicesConsolidationsTableMobile,
      InfoPopup,
      ValueHelper,
      ShowMore,
      NoResult,
      LinkButton,
      DefaultCheckbox,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile, consolidationMixin],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        show1: false,
        dateInvoice: this.$moment().format('YYYY-MM-DD'),

        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,

        showPopup: false,
      }
    },

    methods: {

      changeDate(value) {
        this.dateInvoice = value
      },

      toggleShowPopup(val) {
        this.showPopup = val
      },

      showInvoice(id) {
        this.$store.dispatch('getInvoices', id).then((response) => {

          if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.toggleShowPopup(true)
          }
        })
      },

    }

  }
</script>

<style lang="scss" scoped>
.site-table .ellipsis {
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
}
  .invoices-table {
    @media (min-width: 1700px) {
      th:last-child,
      td:last-child {
        padding-left: 150px;
      }
    }
  }
</style>
