<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
            class="mt-1"
            :selectedNow="selectedNow"
            :dataValue="item.id"
        />

        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInvoice')">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_ID.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
               <span class="table-link btn-style"
                     @click="$emit('showInvoice')">
                   #{{item.id}}
                </span>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-info">
            <div class="table-card__item-col custom-col" v-if="isAdmin">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                    :item="item"
                    :mobile="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_Payment.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="d-flex align-items-center" >
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>

              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Type.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id" class="d-flex">
                <div class="table-ico mr-8" style="width: 15px">
                  <img src="/img/company-icons-group/storage.svg" alt="ico">
                </div>
                <div class="mt-1">{{ $t('payments_StorageCost.localization_value.value') }}</div>
              </div>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id" class="d-flex">
                <div class="table-ico mr-8" style="width: 15px">
                  <LabelCircle :color="'gray-light'" :value="'O'"/>
                </div>
                <div class="mt-1">{{ $t('invoices_Other.localization_value.value') }}</div>
              </div>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_SMS.id" class="d-flex">
                <div class="table-ico mr-8" style="width: 15px">
                  <LabelCircle :color="'gray-light'" :value="'S'"/>
                </div>
                <div class="mt-1">{{ $t('invoices_SMS.localization_value.value') }}</div>
              </div>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id" class="table-row">
  							<span class="table-ico disposal mr-2" style="width: 15px">
  								<img src="/img/company-icons-group/trash-can1.svg"  alt="ico">
  							</span>
                {{ $t('invoices_Disposal.localization_value.value') }}
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Comment.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <TooltipTextHelper
                  :fullTextWidth="110"
                  :text="item.comment"
                  :paragraph="item.comment"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Storage.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'storage_cost_amount'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Shipping.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'net_cost_amount'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Order.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'order_price_amount'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Packaging.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'packaging_amount'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Total.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item"
                  :deep="'total_amount'"
              />
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('mypayments_printInvoice.localization_value.value')"
                :type="'print'"
                :datePicker="true"
                :popup-class="'mobile-center'"
                :datePickerRange="false"
                :datePickerTxt="'Print Invoice'"
                :datePickerFormat="'YYYY-MM-DD'"
                @changeDate="(val) => $emit('changeDate', val)"
                @datePickerBtn="$emit('printInvoice')"
            />
          </div>
          <div
              class="table-card__item-btn-i"
          >
            <LinkButton
                v-if="isAdmin"
                :value="$t(`common_edit.localization_value.value`)"
                :type="'edit'"
                :link="$store.getters.GET_PATHS.financeInvoices + '/edit/' + item.id"
            />
          </div>
          <div
              class="table-card__item-btn-i"
              v-if="isAdmin && item.status === INVOICES_STATUSES.INVOICES_STATUS_PAYED.value"
          >
            <LinkButton

                :value="$t(`invoices_refund.localization_value.value`)"
                :type="'return'"
                @click.native="$emit('refundInvoice', item.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import TooltipTextHelper from "../../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "../../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableUserColumn from "../../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {INVOICES_TYPES} from "../../../../../../../../../staticData/staticVariables";
  import {INVOICES_STATUSES} from "../../../../../../../../../staticData/staticVariables";
  import LabelCircle from "../../../../../../../../coreComponents/LabelCircle/LabelCircle";

  export default {
    name: "InvoicesOtherTableMobile",
    components: {
      LabelCircle,
      TableUserColumn,
      DefaultCheckbox,
      LinkButton,
      ValueHelper,
      StatusIcoBtn,
      TooltipTextHelper
    },


    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        INVOICES_TYPES: INVOICES_TYPES,
        INVOICES_STATUSES: INVOICES_STATUSES
      }
    },

  }
</script>

<style scoped>
.ellipsis {
  max-width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
