<template>
  <div class="fragment">
            <!--@changeTab="(item) => mainChangeTab(item, tabs)"-->
    <InvoicesTableUser
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :sortParams="sortParams"
            @changeTab="changeTabFunc"
            @changeSort="data => changeSort(data)"
            @resetFilter="resetFilter"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @reload="reload"
            @printInvoice="printInvoice"
            @refundInvoice="refundInvoice"
            @downloadFiles="downloadFiles"
    />

    <PrintInvoicePopup
        v-if="printInvoicePopup"
        :printData="printData"
        @close="printInvoicePopup = false"
    />
  </div>
</template>

<script>
  import InvoicesTableUser from "./InvoicesTableSections/InvoicesTableSections.vue";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import {
    CDEK_DELIVERY_SERVICE,
    MEEST_DELIVERY_SERVICE,
    NOVAPOSHTA_DELIVERY_SERVICE
  } from "../../../../../staticData/staticVariables";
  import PrintInvoicePopup from "@/components/modules/InvoicesModule/popups/PrintInvoicePopup/PrintInvoicePopup";

  export default {
    name: "InvoicesTable",
    components: {
      PrintInvoicePopup,
      InvoicesTableUser,
    },

    mixins: [routeFilter, radioButtonsFunc],


    watch: {
      loadUserAuthorizedData: function () {
        if(this.downloadPermissions) {
          this.firstActiveTabByUserType()
        }
      },

      downloadPermissions() {
        if(this.loadUserAuthorizedData){
          this.checkTabPermission()
        }
      },

    },


    data() {
      return {

        printInvoicePopup: false,
        printData: null,

        forPage: this.$store.getters.getInvoicesForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterTypeId: '',
        filterDate: [],
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterOrderNumber: '',
        filterTrackParcel: '',
        filterPayment: '',
        filterProcessing: '',
        filterShipping: '',
        filterPackaging: '',
        filterInsurance: '',
        filterOrder: '',
        filterTotal: '',
        filterStatus: '',
        filterFBAStatus: '',
        filterCostSet: '',
        filterOtherType: '',
        filterSendFrom: '',


        sortParams: {
          sortId: '',
          sortDate: '',
          sortOrderNumber: '',
          sortPayment: '',
          sortShipping: '',
          sortOrder: '',
          sortPackaging: '',
          sortGiftMessage: '',
          sortCustomization: '',
          sortSkladUSAFee: '',
          sortSkladUSAInsurance: '',
          sortInsurance: '',
          sortTotal: '',
        },

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          novaPoshta: {
            id: 1,
            label: 'invoices_NovaPoshta',
            name: 'novaPoshta',
            active: false,
            hiddenOptionArr: [
              'isUkrainian'
            ],
            permissionVisible: false,
          },
          meest: {
            id: 9,
            label: 'invoices_Meest',
            name: 'meest',
            active: false,
            // hiddenOptionArr: [
              // 'isUkrainian'
            // ],
            permissionVisible: false,
          },
          cdek: {
            id: 2,
            label: 'invoices_CDEK',
            name: 'cdek',
            active: false,
            hiddenOptionArr: [
              'isBelarus', 'isSNGGroup'
            ],
            permissionVisible: false,
          },
          orders: {
            id: 3,
            label: 'invoices_Orders',
            name: 'orders',
            active: false,
            permissionVisible: false,
          },
          consolidation: {
            id: 4,
            label: 'invoices_Consolidation',
            name: 'consolidation',
            active: false,
            permissionVisible: false,
          },
          DHLFedExTNT: {
            id: 5,
            label: 'invoices_DHLFedExTNT',
            name: 'DHLFedExTNT',
            active: false,
            permissionVisible: false,
          },
          FBA: {
            id: 6,
            label: 'invoices_FBA',
            name: 'FBA',
            active: false,
            permissionVisible: false,
          },
          inbound: {
            id: 7,
            label: 'invoices_FBAReturns',
            name: 'inbound',
            active: false,
            permissionVisible: true,
          },
          other: {
            id: 8,
            label: 'invoices_Other',
            name: 'other',
            active: false,
            permissionVisible: false,
          },
        },
      }
    },

    mounted() {
      let isChangeRoute = false
      if(this.loadUserAuthorizedData && this.downloadPermissions){
        isChangeRoute = this.firstActiveTabByUserType()
      }

      if(isChangeRoute){
        return
      }

      if(this.$route.query.type && this._.has(this.navTabs, this.$route.query.type)){
        let tab = this.navTabs[this.$route.query.type]

        this.skipRadioChecked(this.navTabs, 'active')
        tab.active = true
      }

      if(this.downloadPermissions){
        this.checkTabPermission()
      }

    },

    methods: {

      changeTabFunc(tab, tabs = false) {
        this.$router.push({path: '', query: {}})
        this.changeTab(tab, tabs)
      },

      getSortParam() {
        this.sortParams = {
          sortId: this.$route.query.sortId || '',
          sortDate: this.$route.query.sortDate || '',
          sortOrderNumber: this.$route.query.sortOrderNumber || '',
          sortPayment: this.$route.query.sortPayment || '',
          sortShipping: this.$route.query.sortShipping || '',
          sortOrder: this.$route.query.sortOrder || '',
          sortPackaging: this.$route.query.sortPackaging || '',
          sortGiftMessage: this.$route.query.sortGiftMessage || '',
          sortCustomization: this.$route.query.sortCustomization || '',
          sortSkladUSAFee: this.$route.query.sortSkladUSAFee || '',
          sortSkladUSAInsurance: this.$route.query.sortSkladUSAInsurance || '',
          sortInsurance: this.$route.query.sortInsurance || '',
          sortTotal: this.$route.query.sortTotal || '',
        }
      },

      firstActiveTabByUserType(){

        if(Object.keys(this.$route.query).length > 0) return false

        this.navTabs.novaPoshta.active = (this.isUkrainian || this.isAdmin)

        if(!this.navTabs.novaPoshta.active)
          this.navTabs.cdek.active = (this.isSNGGroup || this.isBelarus || this.isAdmin)

        if(this.isSNGGroup)
          this.navTabs.DHLFedExTNT.label = 'invoices_FedEx'

        if(this.isBelarus)
          this.navTabs.DHLFedExTNT.label = 'invoices_DHLFedEx'

        if(this.downloadPermissions){
          this.checkTabPermission()
        }
        return true
      },

      checkTabPermission() {
        this.activeTabByPermissions('navTabs', {
          ['novaPoshta']: this.PERMISSIONS.INVOICES_NOVAPOSHTA,
          ['meest']: this.PERMISSIONS.ORDER_MEEST_GENERAL,
          ['cdek']: this.PERMISSIONS.INVOICES_CDEK,
          ['orders']: this.PERMISSIONS.INVOICES_ORDERS,
          ['consolidation']: this.PERMISSIONS.INVOICES_CONSOLIDATION,
          ['DHLFedExTNT']: this.PERMISSIONS.INVOICES_ORDER_EXPRESS,
          ['FBA']: this.PERMISSIONS.INVOICES_FBA,
          // ['inbound']: this.PERMISSIONS.I,
          ['other']: this.PERMISSIONS.INVOICES_OTHER,
        })

        console.log(this.navTabs);

        if(!this._.find(this.navTabs, {active: true})?.permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },

      getFilterParam() {

        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,

          id: this.$route.query.id,
          orderNumber: this.$route.query.orderNumber,
          trackParcel: this.$route.query.trackParcel,
          payment: this.$route.query.payment,
          processing: this.$route.query.processing,
          shipping: this.$route.query.shipping,
          packaging: this.$route.query.packaging,
          insurance: this.$route.query.insurance,
          order: this.$route.query.order,
          total: this.$route.query.total,
          status: this.$route.query.status,
          fBAStatus: this.$route.query.fBAStatus,
          costSet: this.$route.query.costSet,
          otherType: this.$route.query.otherType,
          sendFrom: this.$route.query.sendFrom,
        }
      },

      filter(next = false) {
        this.getFilterParam()
        this.getSortParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        if (next) this.$store.commit('setNextInvoicesPage', true)
        this.$store.dispatch('fetchInvoices', url).then(() => {
          this.$store.commit('setNextInvoicesPage', false)
        })


        this.checkCountFilter(['user', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterId: 'InvoiceId',
            filterUserId: 'InvoiceUserId',
            // filterPayment: 'OrderInvoicesNewPostTrackingNumber',
            filterShipping: 'InvoiceTotalCostAmount',
            filterProcessing: 'InvoiceFeeAmount',
            filterPackaging: 'InvoicePackagingAmount',
            filterInsurance: 'InvoiceInsuranceAmount',
            filterOrder: 'InvoiceOrderPriceAmount',
            filterTotal: 'InvoiceTotalAmount',
            filterStatus: 'InvoiceStatus',
            // filterFBAStatus: 'proform_entity_naming',
            filterCostSet: 'InvoiceNetCostAmount',
            filterSendFrom: 'orderFbmInvoicesBySendCompany',
            // filterOtherType: 'proform_entity_naming',

            // filterType: 'orderExpressInvoices'
          },
        )

        switch (true) {
          /**
           * NOVAPOSHTA
           */
          case this.navTabs.novaPoshta.active:
            this.filterType = NOVAPOSHTA_DELIVERY_SERVICE.id
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderInternalInvoicesByDeliveryServiceId',
                filterTrackParcel: 'byTrackingNumberInternal',
                filterOrderNumber: 'byOrderInternalId',
              },
            )
            break

          /**
           * NOVAPOSHTA
           */
          case this.navTabs.meest.active:
            this.filterType = MEEST_DELIVERY_SERVICE.id
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'meestExpressInvoices',
                // filterTrackParcel: 'byTrackingNumberInternal',
                filterOrderNumber: 'byOrderInternalId',
              },
            )
            break

            /**
             * CONSOLIDATIONS
             */
          case this.navTabs.consolidation.active:
            this.filterType = 'conso'
            this.generateFilterQueryParams(
                myQuery,
                {
                  filterType: 'consolidationInvoices',
                  filterTrackParcel: 'byTrackingNumberConsolidation',
                  filterOrderNumber: 'consolidationInvoicesId',
                },
            )
            break

          /**
           * CDEK
           */
          case this.navTabs.cdek.active:
            this.filterType = CDEK_DELIVERY_SERVICE.id
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderInternalInvoicesByDeliveryServiceId',
                filterTrackParcel: 'byTrackingNumberInternal',
                filterOrderNumber: 'byOrderInternalId',
              },
            )
            break

          /**
           * DHL FedEx TNT
           */
          case this.navTabs.DHLFedExTNT.active:
            this.filterType = 'DHLFedExTNT'
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderExpressInvoices',
                filterTrackParcel: 'byTrackingNumber',
                filterOrderNumber: 'byOrderExpressId',
              },
            )
            myQuery.where('excludedStatus', 'wait_for_pay')
            break

          /**
           * FBA
           */
          case this.navTabs.FBA.active:
            this.filterType = 'fba'
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderFbaInvoices',
                filterTrackParcel: 'byTrackingNumber',
                filterOrderNumber: 'orderFbaInvoicesId',
              },
            )
            break

          /**
           * FBM
           */
          case this.navTabs.orders.active:
            this.filterType = 'fbm'
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderFbmInvoices',
                filterTrackParcel: 'byTrackingNumberFbm',
                filterOrderNumber: 'orderFbmInvoicesId',
              },
            )
            break

          /**
           * Inbound
           */
          case this.navTabs.inbound.active:
            this.filterType = 'inbound'
            this.filterTypeId = '1'
            this.generateFilterQueryParams(
              myQuery,
              {
                filterType: 'orderInboundInvoices',
                filterTypeId: 'InvoiceInvoiceTypeId',
                filterTrackParcel: 'byTrackingNumberInbound',
                filterOrderNumber: 'orderInboundInvoicesId',
              },
            )
            break
            /**
             * Other
             */
          case this.navTabs.other.active:
            this.filterType = '5,6,7,8,9'
            this.generateFilterQueryParams(
                myQuery,
                {
                  filterType: 'InvoiceInvoiceTypeId',
                  // filterTrackParcel: 'byTrackingNumber',
                  // filterOrderNumber: 'byOrderExpressId',
                },
            )
            break

        }

        this.generateSortQueryParams(
            myQuery,
            {
              sortId: 'InvoiceId',
              sortDate: 'created_at',
              // sortUserName: 'BalanceOutputCreatedAt',
              sortOrderNumber: 'order_model_id',
              sortPayment: 'InvoiceStatus',
              // sortTrackParcel: 'BalanceOutputUserFullName',
              // sortProcessing: 'BalanceOutputUserBalance',
              sortShipping: 'InvoiceNetCostAmount', // Shipping Cost
              sortOrder: 'InvoiceOrderPriceAmount',
              sortPackaging: 'InvoicePackagingAmount',
              sortGiftMessage: 'InvoiceGiftMessageAmount',
              sortCustomization: 'customizing_price', //Customization price
              sortSkladUSAFee: 'customizing_fee', // SkladUSA Fees
              sortSkladUSAInsurance: 'insurance_sklad_usa', // SkladUSA Insurance
              sortInsurance: 'invoices_insurance_amount',
              sortTotal: 'InvoiceTotalAmount',
            }
        )

        console.log(myQuery.url());


        // let deliveryTypeId = ''
        // switch (this.filterType) {
        //   case DHL_DELIVERY_SERVICE.name:
        //     deliveryTypeId = DHL_DELIVERY_SERVICE.id
        //     break
        //   case FEDEX_DELIVERY_SERVICE.name:
        //     deliveryTypeId = FEDEX_DELIVERY_SERVICE.id
        //     break
        //   case TNT_DELIVERY_SERVICE.name:
        //     deliveryTypeId = TNT_DELIVERY_SERVICE.id
        //     break
        // }
        // if (deliveryTypeId !== '')
        //   myQuery.where('OrderInvoicesPaymentDeliveryServiceId', deliveryTypeId)


        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'betweenCreatedAt',
            date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        let name = this.$t(`${this._.find(this.navTabs, {active: true}).label}.localization_value.value`)
        let fileName = `Invoices ${name}`

        this.exportTableFiles(url, type, 'getExportInvoices', fileName)

        // this.$store.dispatch('getExportInvoices', {filter: url , type: type}).then((response) => {
        //   if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //     if(type !== 'csv' && type !== 'xlsx') {
        //       this.openNotify('success', 'common_notificationRecordChanged')
        //       return
        //     }
        //
        //     const blob = new Blob([response.data], {type: `application/${type}`})
        //     const link = document.createElement('a')
        //     link.href = URL.createObjectURL(blob)
        //     let name = this.$t(`${this._.find(this.navTabs, {active: true}).label}.localization_value.value`)
        //     link.download = `Invoices ${name}.${type}`
        //     link.click()
        //     URL.revokeObjectURL(link.href)
        //   }
        //   else {
        //     setTimeout(() => {
        //       this.openNotify('error', 'common_notificationUndefinedError')
        //     }, 200)
        //   }
        // }).catch(error => this.createErrorLog(error))

      },

      printInvoice(data) {
        let exportIds = this.getCheckedRows('row-name').join(',')

        if(!Object.hasOwnProperty.call(data, 'selected')) {
          exportIds = data.id
        }

        if (exportIds.length === 0) return

        let postData = {
          invoiceIds: exportIds,
          date: data.date
        }

        this.printData = postData
        this.printInvoicePopup = true

      },

      refundInvoice(id) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('cancelInvoices', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordChanged')
                this.filter()
                this.$emit('close')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setInvoices', {invoices: []})
        this.$store.commit('setInvoicesCommonList', {invoicesCommonList: []})
      }, 300)
    },
  }
</script>

<style scoped>

</style>
