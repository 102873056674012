<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
                class="mt-1"
                :selectedNow="selectedNow"
                :dataValue="item.id"
        />

        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInvoice')">
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_ID.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
               <span class="table-link btn-style"
                     @click="$emit('showInvoice')">
                   #{{item.id}}
                </span>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-info">
            <div class="table-card__item-col custom-col" v-if="isAdmin">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                        :item="item"
                        :mobile="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_OrderNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.invoice_instance.instance.order_type_id).name + '/' + item.invoice_instance.instance.id"
                >
                  {{item.invoice_instance.instance.id}}
                </router-link>
              </template>
              <template v-if="item.invoice_instance && !item.invoice_instance.instance">
                {{item.invoice_instance.model_id}}
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_Payment.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="d-flex align-items-center" >
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>

              </div>
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Packaging.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'packaging_amount'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_TrackParcelNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="table-row ellipsis">
                    <ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/>
                  </div>
                  <template slot="popover">
                    <p><ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/></p>
                  </template>
                </v-popover>
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('fbm_GiftMessage.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.gift_message_amount"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('fbm_engravingPrice.localization_value.value') }}
            </div>
            <div class="table-card__item-info"><ValueHelper
                v-if="item.engraving_cost && Number(item.engraving_cost) > 0"
                :value="item.engraving_cost"
                :costType="true"
                :costCurrencyType="'dollar'"
            />
              <ValueHelper
                  v-if="item.embroidery_cost && Number(item.embroidery_cost) > 0"
                  :value="item.embroidery_cost"
                  :costType="true"
                  :costCurrencyType="'dollar'"
              />
              <ValueHelper
                  v-if="item.tshirt_painting_cost && Number(item.tshirt_painting_cost) > 0"
                  :value="item.tshirt_painting_cost"
                  :costType="true"
                  :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50" v-if="isAdmin">
            <div class="table-card__item-label">
              {{ $t('invoices_SkladusaCost.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'real_shipping_cost'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('fbm_shippingCost.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'net_cost_amount'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('fbm_OrderPrice.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.order_price_amount"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('fba_skladUsaFee.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'fee_amount'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_fbmSkladUSAInsurance.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'summary_data.insurance_sklad_usa'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_fbmInsuranceEasyPost.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'summary_data.insurance_from_delivery_system'"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Total.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.total_amount"
                      :costType="true"
                      :costCurrencyType="'dollar'"
              />
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('mypayments_printInvoice.localization_value.value')"
                    :type="'print'"
                    :datePicker="true"
                    :popup-class="'mobile-center'"
                    :datePickerRange="false"
                    :datePickerTxt="'Print Invoice'"
                    :datePickerFormat="'YYYY-MM-DD'"
                    @changeDate="(val) => $emit('changeDate', val)"
                    @datePickerBtn="$emit('printInvoice')"
            />
          </div>
          <div
              class="table-card__item-btn-i"
              v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
          >
            <LinkButton

                :value="$t(`invoices_refund.localization_value.value`)"
                :type="'return'"
                @click.native="$emit('refundInvoice', item.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import TooltipTextHelper from "../../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "../../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBAMixin} from "../../../../../../../../../mixins/FBAMixins/FBAMixin";
  import {FBA_ORDER_STATUSES} from "../../../../../../../../../staticData/staticVariables";
  import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TableUserColumn from "../../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../../staticData/staticVariables";
  import {FBMMixinsHelper} from "../../../../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "InvoicesFBATableMobile",
    components: {TableUserColumn, DefaultCheckbox, LinkButton, ValueHelper, StatusIcoBtn, TooltipTextHelper},

    mixins: [FBAMixin, FBMMixinsHelper],

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        FBA_ORDER_STATUSES: FBA_ORDER_STATUSES,
        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,
      }
    },

  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
