<template>
  <div>
    <template v-if="$store.getters.getInvoices.length > 0 && $store.getters.getInvoicesLoading !== true">

      <div class="site-table-wrap invoices-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'invoices_ID',
            'invoices_Date',
            'invoices_OrderNumber',
            'invoices_TrackParcelNumber',
            'invoices_Payment',
            'invoices_Processing',
            'invoices_Shipping',
            'invoices_Packaging',
            'invoices_Insurance',
            'invoices_Order',
            'invoices_Total',
            'common_manage',
          ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('invoices_ID.localization_value.value') }}</th>
            <th>{{ $t('invoices_Date.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th>{{ $t('invoices_OrderNumber.localization_value.value') }}</th>
            <th>{{ $t('invoices_TrackParcelNumber.localization_value.value') }}</th>
            <th>{{ $t('invoices_Payment.localization_value.value') }}</th>
            <th>{{ $t('invoices_Shipping.localization_value.value') }}</th>
            <th width="100%"></th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getInvoices" :key="index">
            <td>
              <DefaultCheckbox
                      class="empty-label"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
                      @showInvoice="showInvoice(item.id)"
              />
            </td>
            <td>
              <span class="table-link btn-style"
                    @click="showInvoice(item.id)"
              >
                #{{item.id}}
              </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                      :item="item"
              />
            </td>
            <td>
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersMeestReturns + '/show/' + item.invoice_instance.instance.id"
                >
                  {{item.invoice_instance.instance.id}}
                </router-link>

              </template>
            </td>
            <td>
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <TooltipTextHelper :fullTextWidth="150">
                  <template slot="text">
                    <ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/>
                  </template>
                  <template slot="paragraph">
                    <ValueHelper :value="item" :deep="'invoice_instance.instance.tracking_number'"/>
                  </template>
                </TooltipTextHelper>
              </template>
            </td>
            <td>
              <div class="d-flex align-items-center mb-1"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'common_Paid',
                  'invoices_AccountBalance',
                ])"></div>
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1 mt-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1 mt-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>
              </div>
            </td>
            <td class="text-right">
              {{item.total_amount}}
            </td>

            <td width="100%">
            </td>

            <td width="15%">
              <div class="table-right table-manage-list">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t('mypayments_printInvoice.localization_value.value')"
                        :type="'print'"
                        :datePicker="true"
                        @changeDate="changeDate"
                        @datePickerBtn="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                        :popup-class="'mobile-center'"
                        :datePickerRange="false"
                        :datePickerTxt="$t('invoices_print.localization_value.value')"
                        :datePickerFormat="'YYYY-MM-DD'"
                    />
                    <LinkButton
                        v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
                        :value="$t(`invoices_refund.localization_value.value`)"
                        :type="'return'"
                        @click.native="$emit('refundInvoice', item.id)"
                    />
                    <LinkButton
                        v-if="isAdmin"
                        :value="$t(`common_edit.localization_value.value`)"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.financeInvoices + '/edit/' + item.id"
                    />
                  </template>
                </ManagerButton>

              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getInvoices" :key="index"
            >
              <InvoicesMeestTableMobile
                      :item="item"
                      :selectedNow="selectedNow"
                      @showInvoice="showInvoice(item.id)"
                      @changeDate="(date) => changeDate(date)"
                      @printInvoice="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                      @refundInvoice="(data) => $emit('refundInvoice', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getInvoicesCommonList.next_page_url !== null && $store.getters.getInvoices.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextInvoicesPage}"
                    :count="$store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page < $store.getters.getInvoicesForPage ?
                  $store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page:
                  $store.getters.getInvoicesForPage"
            />
            <ExportBtn
                :customType="_.has($store.getters.getInvoicesCommonList, 'addition_export') ? $store.getters.getInvoicesCommonList.addition_export : null"
                @downloadFiles="type => $emit('downloadFiles', type)"
                class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>


    </template>

    <template v-if="$store.getters.getInvoicesLoading === false && $store.getters.getInvoices.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <InfoPopup
            v-if="showPopup"
            :typeInvoice="'novaPoshta'"
            @close="toggleShowPopup(false)"
    />

  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import InfoPopup from "../../../../../popups/InfoPopup/InfoPopup";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import InvoicesMeestTableMobile from "./InvoicesMeestTableMobile/InvoicesMeestTableMobile";
  import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../staticData/staticVariables";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import ExportBtn from '../../../../../../../coreComponents/Buttons/Export/Export'

  export default {
    name: "InvoicesMeestTable",
    components: {
      TooltipTextHelper,
      ManagerButton,
      ShowMore,
      TableUserColumn,
      InfoPopup,
      ValueHelper,
      NoResult,
      LinkButton,
      StatusIcoBtn,
      DefaultCheckbox,
      InvoicesMeestTableMobile,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        show1: false,
        dateInvoice: this.$moment().format('YYYY-MM-DD'),
        showPopup: false,
        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,
      }
    },

    methods: {

      changeDate(value) {
        this.dateInvoice = value
      },

      toggleShowPopup(val) {
        this.showPopup = val
      },

      showInvoice(id) {
        this.$store.dispatch('getInvoices', id).then((response) => {
          console.log(response.data);
          console.log(response.data.status);
          if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.toggleShowPopup(true)
          }
        })
      },

    }

  }
</script>

<style lang="scss" scoped>

  .invoices-table {
    td, th {
      white-space: nowrap;
    }
  }
</style>
