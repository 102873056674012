<template>
  <div>
    <template v-if="$store.getters.getInvoices.length > 0 && $store.getters.getInvoicesLoading !== true">
      <div class="site-table-wrap invoices-table" v-if="!isMobileFunc()"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'invoices_ID',
            'invoices_Date',
            'invoices_OrderNumber',
            'invoices_Payment',
            'invoices_Type',
            'invoices_Comment',
            'invoices_Storage',
            'invoices_Disposal',
            'invoices_SMS',
            'invoices_Total',
            'common_manage',
          ])"></div>
        <table class="site-table">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('invoices_ID.localization_value.value') }}</th>
            <th>{{ $t('invoices_Date.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th>{{ $t('invoices_Payment.localization_value.value') }}</th>
            <th>{{ $t('invoices_Type.localization_value.value') }}</th>
            <th>{{ $t('invoices_Comment.localization_value.value') }}</th>
            <th>{{ $t('invoices_Storage.localization_value.value') }}</th>
            <th>{{ $t('invoices_Shipping.localization_value.value') }}</th>
            <th>{{ $t('invoices_Order.localization_value.value') }}</th>
            <th>{{ $t('invoices_Packaging.localization_value.value') }}</th>
            <th>{{ $t('invoices_Total.localization_value.value') }}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getInvoices" :key="index">
            <td>
              <DefaultCheckbox
                  class="empty-label"
                  :selectedNow="selectedNow"
                  :dataValue="item.id"
              />
            </td>
            <td>
              <span class="table-link btn-style"
                    @click="showInvoice(item.id)"
              >
                   #{{item.id}}
                </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td v-if="isAdmin">
              <TableUserColumn
                  :item="item"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else-if="item.status === 'draft'">
                      <StatusIcoBtn :type="'in-progress'" class="mr-1"/>  {{$t('invoices_draft.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else-if="item.status === 'draft'">
                        <StatusIcoBtn :type="'in-progress'" class="mr-1"/>  {{$t('invoices_draft.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>
              </div>
            </td>
            <td>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id" class="table-row">
                <span class="table-ico mr-8">
                  <img src="/img/company-icons-group/storage.svg" alt="ico">
                </span>
                {{ $t('payments_StorageCost.localization_value.value') }}
              </div>
  <!--            <div class="table-row">-->
  <!--              <LabelCircle :color="'green'" :value="'E'" class="mr-8"/>-->
  <!--              Easy-->
  <!--            </div>-->
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id" class="table-row">
                <LabelCircle :color="'gray-light'" :value="'O'" class="mr-8"/>
                {{ $t('invoices_Other.localization_value.value') }}
              </div>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_SMS.id" class="table-row">
                <LabelCircle :color="'gray-light'" :value="'S'" class="mr-8"/>
                {{ $t('invoices_SMS.localization_value.value') }}
              </div>
  <!--            <div class="table-row">-->
  <!--							<span class="table-ico mr-8">-->
  <!--								<img src="/img/company-icons-group/sdek.png" alt="ico">-->
  <!--							</span>-->
  <!--              CDEK-->
  <!--            </div>-->
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id" class="table-row">
  							<span class="table-ico disposal mr-8">
  								<img src="/img/company-icons-group/trash-can1.svg" alt="ico">
  							</span>
                {{ $t('invoices_Disposal.localization_value.value') }}
              </div>
              <div v-if="item.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_MEEST.id" class="table-row">
  							<span class="table-ico disposal mr-8">
                <LabelCircle :color="'orange'" :value="'M'" class="mr-8"/>
  							</span>
                {{ $t('invoices_Meest.localization_value.value') }}
              </div>

            </td>
            <td>
              <TooltipTextHelper
                  :fullTextWidth="170"
                  :text="item.comment"
                  :paragraph="item.comment"
              />
            </td>

            <td>
              <ValueHelper
                  :value="item"
                  :deep="'storage_cost_amount'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'net_cost_amount'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'order_price_amount'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'packaging_amount'"
              />
            </td>
            <td>
              <ValueHelper
                  :value="item"
                  :deep="'total_amount'"
              />
            </td>

            <td width="15%">
              <div class="table-right table-manage-list">
                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                  <template slot="item">
                    <LinkButton
                        :value="$t('mypayments_printInvoice.localization_value.value')"
                        :type="'print'"
                        :datePicker="true"
                        @changeDate="changeDate"
                        @datePickerBtn="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                        :popup-class="'mobile-center'"
                        :datePickerRange="false"
                        :datePickerTxt="$t('invoices_print.localization_value.value')"
                        :datePickerFormat="'DD-MM-YYYY'"
                    />
                    <LinkButton
                        v-if="isAdmin"
                        :value="$t(`common_edit.localization_value.value`)"
                        :type="'edit'"
                        :link="$store.getters.GET_PATHS.financeInvoices + '/edit/' + item.id"
                    />
                    <LinkButton
                        v-if="isAdmin && item.status === INVOICES_STATUSES.INVOICES_STATUS_PAYED.value"
                        :value="$t(`invoices_refund.localization_value.value`)"
                        :type="'return'"
                        @click.native="$emit('refundInvoice', item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

      </div>


      <div class="table-card" v-else>
        <div class="table-card__list">
          <div class="table-card__item"
               v-for="(item, index) in $store.getters.getInvoices" :key="index">
            <InvoicesOtherTableMobile
                :item="item"
                :selectedNow="selectedNow"
                @showInvoice="showInvoice(item.id)"
                @changeDate="(date) => changeDate(date)"
                @printInvoice="$emit('printInvoice', {id: [item.id], date: dateInvoice})"
                @refundInvoice="(data) => $emit('refundInvoice', data)"
            />
          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getInvoicesCommonList.next_page_url !== null && $store.getters.getInvoices.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextInvoicesPage}"
              :count="$store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page < $store.getters.getInvoicesForPage ?
                  $store.getters.getInvoicesCommonList.total - $store.getters.getInvoicesForPage * $store.getters.getInvoicesCommonList.current_page:
                  $store.getters.getInvoicesForPage"
          />
          <ExportBtn
              :customType="_.has($store.getters.getInvoicesCommonList, 'addition_export') ? $store.getters.getInvoicesCommonList.addition_export : null"
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getInvoicesLoading === false && $store.getters.getInvoices.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <InfoPopup
        v-if="showPopup"
        :typeInvoice="invoiceType"
        @close="toggleShowPopup(false)"
    />
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  //import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import InvoicesOtherTableMobile from "./InvoicesOtherTableMobile/InvoicesOtherTableMobile";
  import InfoPopup from "../../../../../popups/InfoPopup/InfoPopup";
  import NoResult from "../../../../../../../coreComponents/NoResult/NoResult";
  import ManagerButton from "../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {INVOICES_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import {INVOICES_TYPES} from "../../../../../../../../staticData/staticVariables";
  import LabelCircle from "../../../../../../../coreComponents/LabelCircle/LabelCircle";
  import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../../coreComponents/Buttons/Export/Export'

  export default {
    name: "InvoicesOtherTable",
    components: {
      ShowMore,
      LabelCircle,
      ManagerButton,
      NoResult,
      InfoPopup,
      InvoicesOtherTableMobile,
      TooltipTextHelper,
      ValueHelper,
      TableUserColumn,
      //LabelCircle,
      LinkButton,
      StatusIcoBtn,
      DefaultCheckbox,
      ExportBtn,
    },

    mixins: [mixinDetictingMobile],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        showPopup: false,
        dateInvoice: this.$moment().format('YYYY-MM-DD'),

        INVOICES_STATUSES: INVOICES_STATUSES,
        INVOICES_TYPES: INVOICES_TYPES,

        invoiceType: '',
      }
    },

    methods: {

      toggleShowPopup(val) {
        this.showPopup = val
      },

      showInvoice(id) {
        this.$store.dispatch('getInvoices', id).then((response) => {
          if(this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            let currentInvoice = this.$store.getters.getInvoicesItem
            switch (currentInvoice?.invoice_type_id) {
              case this.INVOICES_TYPES.INVOICES_TYPE_CUSTOM.id: {
                this.invoiceType = 'custom'
                break
              }
              case this.INVOICES_TYPES.INVOICES_TYPE_STORAGE_USAGE.id: {
                this.invoiceType = 'storage'
                break
              }
              case this.INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id: {
                this.invoiceType = 'storage'
                break
              }
            }
            this.toggleShowPopup(true)
          }
        })
      },

      changeDate(value) {
        this.dateInvoice = value
      },

    }

  }
</script>

<style scoped lang="scss">

  .table-row{
    line-height: 13px;
  }

  .site-table .ellipsis {
    max-width: 170px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  }

  .table-ico{
    width: 16px;

    &.disposal{
      width: 10px;
    }
  }
</style>
