import {FBA_ORDER_STATUSES, FBA_STATUS_COMPLETED} from "../../staticData/staticVariables";

export const FBAMixin = {

  computed: {
    isWarehouseBuyLabel() {
      return this.FBA.data.userLabelTypes.warehouseBuyLabel === this.FBA.data.userLabelType
    },

    selectedCountry(){
      return this.FBA.data.Delivery.delivery.country
    },

  },

  methods: {

    checkProgressStepsSetting() {
      if(this.FBA.data.userLabelTypes.warehouseBuyLabel === this.FBA.data.userLabelType) {
        this.progressStepsSetting = [
          this.$t('fba_productsStep.localization_value.value'),
          this.$t('fba_shippingDetailsStep.localization_value.value'),
          this.$t('fba_shipping.localization_value.value'),
          this.$t('fba_confirmationStep.localization_value.value'),
        ]
      } else {
        this.progressStepsSetting = [
          this.$t('fba_productsStep.localization_value.value'),
          this.$t('fba_shippingDetailsStep.localization_value.value'),
          this.$t('fba_confirmationStep.localization_value.value'),
        ]
      }
    },

    async nextPageHandler() {
      let valid = true
      let response

      switch (this.activeStep) {
        case 1:
          valid = this.FBA.productsValidation()
          break
        case 2:
          valid = this.FBA.shipmentDetailsValidation()
          break
        case 3:
          if(this.FBA.data.userLabelTypes.warehouseBuyLabel !== this.FBA.data.userLabelType) {
            break
          }
          // valid = this.FBA.shipmentDetailsValidation()
          valid = true
          if(!this.FBA.getShippingPartner()?.id){
            valid = false
            this.openNotify('error', 'fbm_ChooseShippingType')
          }
          if(this.FBA.data.useEasyPost && !this.FBA.getShippingAPIPrice()?.driver_delivery_method_id){
            valid = false
            this.openNotify('error', 'fbm_ChooseShippingPrice')
          }
          response = await this.getShippingPrices(false, true)
          if(Object.keys(response).length === 0) {
            this.openNotify('error', 'fbm_NoPricesAvailable')
            valid = false
          }
          if(response?.errors) {
            valid = false
          }
          break
      }

      if (valid)
        if (this.activeStep < this.progressStepsSetting.length) this.activeStep = this.activeStep + 1
    },

    prevPageHandler() {
      if (this.activeStep > 1) this.activeStep = this.activeStep - 1;
    },


    isStatusCompleted(item){
      return item['status'] === FBA_STATUS_COMPLETED.value
    },

    changeUser(user, resetProduct = true) {
      this.FBA.data.User.setUserData(user)
      if(resetProduct) {
        this.FBA.resetProduct()
        this.FBA.resetSets()
      }
    },

    getFileFromServerFc(order, type = false){
      let file = this._.first(order)

      this.downloadFileFromServer(file).then((dataFile) => {
        this.FBA.setFiles(dataFile, type)
      })
    },

    adminOrderValidation(arg) {
      let statusCompleted = arg.isAdmin && arg.edit && this.FBA.getStatus()?.id === FBA_STATUS_COMPLETED.id
      if(arg.isAdmin && !arg.edit && !this.FBA.productsValidation()) return false
      if(arg.isAdmin && arg.edit && !this.FBA.updateAdminValidation(true)) return false

      if(arg.isAdmin && arg.edit && statusCompleted){

        /**
         * Check if declared products quantity equals product quantity in cells
         */
        let checksQuantityInCells = this.FBA.checkProductQuantityInCells()
        if(!checksQuantityInCells.valid){
          let errorTxt = 'In product(s): <br>'
          checksQuantityInCells.errorProducts.map((item, index) => {
            if(index > 0) errorTxt = errorTxt + ', <br>'
            errorTxt = errorTxt + '- ' + item.data.Product.product.productObject?.name_for_fba
          })
          errorTxt = errorTxt + ' <br> declared quantity not equals counts in cells'

          this.openNotify('error', {txtServer: errorTxt})
          return false
        }

        /**
         * Check if quantity products in storage less then product quantity in cells
         */
        // let checksQuantityInStorage = this.FBA.checkProductQuantityInStorage()
        // console.log(checksQuantityInStorage);
        // if(!checksQuantityInStorage.valid){
        //   let errorTxt = 'In product(s): <br>'
        //   checksQuantityInStorage.errorProducts.map((item, index) => {
        //     if(index > 0) errorTxt = errorTxt + ', <br>'
        //     errorTxt = errorTxt + '- ' + item.data.Product.product.productObject?.name_for_fba
        //   })
        //   errorTxt = errorTxt + ' <br> quantity in storage less then in cells'
        //
        //   this.openNotify('error', {txtServer: errorTxt})
        //   return false
        // }

      }

      return true
    },

    save(arg = false) {
      let createLabel = false

      if(arg.isAdmin && !this.adminOrderValidation(arg)){
        return
      }

      if(arg.isAdmin && arg.edit && this.FBA.getStatus()?.id === FBA_ORDER_STATUSES.FBA_STATUS_IN_PROGRESS.id) {
        if(!this.FBA.data.Order.getIsWasBeenCreateLabel() && this.FBA.data.useEasyPost) {
          createLabel = true
        }
      }


      let data = this.FBA.prepareSave({isAdmin: arg.isAdmin, createForUser: arg.createForUser})

      this.saveOrderFBA(arg, data, createLabel).then(response => {
        if(createLabel) {
          this.createLabelFBA(response.id).then(status => {
            if(status) {
              this.successSaveFBA(status, createLabel, response.id, arg.edit)
            }
          })
          return
        }

        this.successSaveFBA(response.status, false, response.id, arg.edit)

      })

    },

    successSaveFBA(status, openLabel = false, id = false, edit = false) {
      if(!status) return

      if(id && this.isAdmin && edit) {
        if(openLabel){
          this.openNotify('success', 'common_notificationRecordChanged')
        }
        this.reloadEditPage(openLabel)
        return
      }

      // if(openLabel && id){
      //   this.$router.push(this.$store.getters.GET_PATHS.ordersFBM + `?openLabel=${id}`)
      //   this.openNotify('success', 'common_notificationRecordCreated')
      //   return
      // }

      this.$router.push(this.$store.getters.GET_PATHS.ordersFBA)
    },

    createLabelFBA(id) {
      let typeDispatch = 'buyLabelFBA'

      return this.$store.dispatch(typeDispatch, id).then(response => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            return true
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            return false
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            return false
          }
        }
      })
    },

    saveOrderFBA(arg, data, createLabel) {
      let saveType = 'createOrderFBA'

      if (arg.edit) {
        data = {
          id: this.FBA.data.Order.getId(),
          data: data
        }
        saveType = 'updateOrderFBA'
      }

      return this.$store.dispatch(saveType, data).then(response => {
        response.status = response?.data?.status || response?.response?.status

        switch (response.status) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(createLabel){
              return {status: true, id: response.data?.data?.id || data.id}
            }

            if (arg.edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            return {status: true, id: response.data?.data?.id || data.id}
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    downloadFiles(type) {
      let forPage, page, url, exportIds

      exportIds = this.getCheckedRows('row-name')


      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(false, this)

      page = generateMainFilter.page
      forPage = generateMainFilter.forPage

      url = this.generateFilterUrl(page, forPage, exportIds)

      console.log(111111, exportIds, url);

      this.$store.dispatch('getOrderFBAExport', {filter: url, exportType: type}).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          this.createDownloadLink({data: response.data, name: `OrdersFBA.${type}`, type: type})
        } else {
          setTimeout(() => {
            this.openNotify('error', 'common_notificationUndefinedError')
          }, 200)
        }
      }).catch(error => this.createErrorLog(error))

    },


    removeItem(id, showFlag = false){
      let text = {
        title: 'fba_DeleteOrder',
        txt: '',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteOrderFBA', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordDeleted')

          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        if(showFlag) {
          this.$router.push(this.$store.getters.GET_PATHS.ordersFBA)
        }
        return true
      }

      this.deleteFunc(text, confirm)
    },


    /**
     * Products components for user and Products & Shipping Details for admin
     * @param item
     * @param val
     */
    changeProductName(item, val) {
      item.setProduct(val)
    },
    changeProductQuantity(item, val) {
      item.setProductCount(val)
    },


    /**
     * ShipmentDetails components for user and Files for admin
     * @param files
     * @param maxSizeError
     * @param fileType
     */
    changeFile(files, maxSizeError, fileType){

      if (files.length === 0) return
      let dataFiles = []

      files.map(fileItem => {
        let file = fileItem.file
        this.getBase64(file).then(data => {
          let dataFile = Object.assign([], files[0])
          let base64 = data.split('base64,')
          dataFile.base64String = base64[1]
          dataFiles.push(dataFile)
        });
      })

      this.FBA.data[fileType.name].setFiles(dataFiles)
      this.FBA.data[fileType.name].setFilesMaxSizeError(maxSizeError)
    },

    removeFile(id, fileType){
      this.$store.dispatch('removeUserDocument', id).then(() => {
        this.FBA.removeFile(id - 1, fileType)
      })
    },





    getShippingPrices(adminManage = false, getPrepayCost = false) {

      let prepareFBA = this.FBA.prepareGetPrices(adminManage)

      let prepareData = Object.assign(prepareFBA, {
        "saturday_delivery" : null,
        "additional_cost" : null,
        "vat_tax" : 'example',
        "require_signature" : null,
        "return_parcel_id" : null,
        "insurance_amount" : null,
        "order_type_id" : 2,
      })

      return this.$store.dispatch('getOrderShippingPrices', prepareData).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            let data = this.getRespData(response)

            if(getPrepayCost){
              return data
            }

            this.FBA.setShippingAPIPrices(data)

            if(data.length === 1){
              this.FBA.setShippingAPIPrice(this._.first(data))
            } else {
              this.FBA.setShippingAPIPrice({})
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;

            this.FBA.setShippingAPIPrices([])
            this.FBA.setShippingAPIPrice({})

            this.notifyErrorHelper(errors)
            return response.response.data
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      }).catch(error => this.createErrorLog(error))
    },


    checkShippingCompany(adminManage = false){
      /**
       * Reset ShippingCompanyData
       */
      this.FBA.setShippingCompanies([])

      /**
       * If load user and not admin or order type Edit
       */
      if(this.FBA.data.User.getUserId() !== ''
        // && (!this.isAdmin || this.$route.params.id)
      ){
        return this.getShippingData(adminManage)
      }
    },

    getShippingData(adminManage = false){

      let prepareFBA = this.FBA.prepareGetPrices(adminManage)
      let prepareData = {
        "recipient_country_id": prepareFBA.recipient_country_id,
        "recipient_zip_code": prepareFBA.recipient_zip_code,
        "storage_id": prepareFBA.storage_id,
        "vat_tax": 'example',
        "order_type": 'sklad_create',
        "require_signature": 0,
        dimension: adminManage ? prepareFBA.admin_dimension : prepareFBA.dimension
      }

      // prepareData['order_type'] = this.FBM.data.Order.getType()?.value

      // prepareData['require_signature'] = prepareFBM.require_signature

      // if(!this.isLabel) {
      //   prepareData['vat_tax'] = 'example'
      // }
      //
      // if(prepareFBM.storage_id) {
      //   prepareData['storage_id'] = prepareFBM.storage_id
      // }
      //
      // if(prepareFBM?.vat_tax?.vat_tax) {
      //   prepareData['vat_tax'] = prepareFBM['vat_tax']['vat_tax']
      // }
      //
      // if(adminManage){
      //   prepareData['delivery_date'] = this.FBM.data.receivedAt
      // }
      //
      // if(this.FBM.getIdReturned()) {
      //   prepareData['return_parcel_id'] = parseInt(this.FBM.getIdReturned())
      // }

      return this.$store.dispatch('getOrderShippingCompany', prepareData).then(response => {
        let data = this.getRespData(response)
        this.FBA.setShippingCompanies(data)
        // fix copy bug when list is empty and we already have shipping type
        if (data.length === 0) {
          this.FBA.setShippingPartnerType({})
          this.FBA.setShippingPartner({})
        }
        // setShippingAPIPrices
      }).catch(error => this.createErrorLog(error))
    },

  }
}


