<template>
  <div class="fragment">
<!--    <InvoicesMainTable-->
<!--            v-if="navTabs.consolidation.active"-->
<!--            @showMore="$emit('showMore')"-->
<!--    />-->
<!--    <InvoicesMainTable-->
<!--        v-if="navTabs.consolidation.active ||-->
<!--              navTabs.orders.active"-->
<!--        @showMore="$emit('showMore')"-->
<!--    />-->

    <InvoicesFBMTable
            v-if="navTabs.orders.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            :sortParams="sortParams"
            @changeSort="data => $emit('changeSort', data)"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesFBATable
            v-if="navTabs.FBA.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesNovaPoshtaTable
            v-if="navTabs.novaPoshta.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

<!--    @printInvoice="(data) => $emit('printInvoice', data)"-->
<!--    @refundInvoice="(data) => $emit('refundInvoice', data)"-->
    <InvoicesMeestTable
            v-if="navTabs.meest.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesCDEKTable
            v-if="navTabs.cdek.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesDHLFedExTNTTable
            v-if="navTabs.DHLFedExTNT.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesConsolidationsTable
        v-if="navTabs.consolidation.active"
        :selectedNow="selectedNow"
        :countFilterParams="countFilterParams"
        @showMore="$emit('showMore')"
        @printInvoice="(data) => $emit('printInvoice', data)"
        @refundInvoice="(data) => $emit('refundInvoice', data)"
        @downloadFiles="type => $emit('downloadFiles', type)"
        @resetFilter="$emit('resetFilter')"
    />

    <InvoicesInboundTable
            v-if="navTabs.inbound.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />

    <InvoicesOtherTable
            v-if="navTabs.other.active"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            @showMore="$emit('showMore')"
            @printInvoice="(data) => $emit('printInvoice', data)"
            @refundInvoice="(data) => $emit('refundInvoice', data)"
            @downloadFiles="type => $emit('downloadFiles', type)"
            @resetFilter="$emit('resetFilter')"
    />


  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import InvoicesMainTable from "./InvoicesMainTable/InvoicesMainTable";
  import InvoicesFBMTable from "./InvoicesFBMTable/InvoicesFBMTable";
  import InvoicesFBATable from "./InvoicesFBATable/InvoicesFBATable";
  import InvoicesInboundTable from "./InvoicesInboundTable/InvoicesInboundTable";
  import InvoicesOtherTable from "./InvoicesOtherTable/InvoicesOtherTable";
  import InvoicesDHLFedExTNTTable from "./InvoicesDHLFedExTNTTable/InvoicesDHLFedExTNTTable";
  import InvoicesNovaPoshtaTable from "./InvoicesNovaPoshtaTable/InvoicesNovaPoshtaTable";
  import InvoicesCDEKTable from "./InvoicesCDEKTable/InvoicesCDEKTable";
  import InvoicesConsolidationsTable from "./InvoicesConsolidationsTable/InvoicesConsolidationsTable";
  import InvoicesMeestTable
    from "@/components/modules/InvoicesModule/components/InvoicesTable/InvoicesTableSections/InvoicesTableBlock/InvoicesMeestTable/InvoicesMeestTable";


  export default {
    name: "InvoicesTableBlock",

    components: {
      InvoicesMeestTable,
      InvoicesConsolidationsTable,
      // InvoicesMainTable,
      InvoicesFBMTable,
      InvoicesFBATable,
      InvoicesInboundTable,
      InvoicesOtherTable,
      InvoicesDHLFedExTNTTable,
      InvoicesNovaPoshtaTable,
      InvoicesCDEKTable,
    },

    mixins: [mixinDetictingMobile],

    props: {
      navTabs: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      sortParams: Object,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,

        dateInvoice: false,

        show1: false,
        show2: false,
        show3: false,


      }
    },

    methods: {

      changeDate(value) {
        this.dateInvoice = value
      },

    }
  }

</script>

<style scoped>
  th:last-child,
  td:last-child{
    padding-left: 150px;
  }
</style>
