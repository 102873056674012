

export const tabsScrollingMixin = {
  data() {
    return {
      hasScrolling: false,
    }
  },

  mounted() {
    setTimeout(() => {this.checkHasScrolling()}, 300)
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    checkHasScrolling() {
      let div = document.querySelector('.content-tabs--scrolling')
      if(!div) return this.hasScrolling = false
      this.hasScrolling = div.scrollWidth > div.clientWidth
      console.log(this.hasScrolling);
      setTimeout(() => {this.checkVisibleScrollBtn()}, 300)
    },

    checkVisibleScrollBtn() {
      let div = document.querySelector('.content-tabs--scrolling')

      console.log(111111);
      console.log(document.querySelector('.scrolling-tabs-left'));
      if(!document.querySelector('.scrolling-tabs-left')) return

      console.log(22222222);
      if(div.scrollLeft === 0) {
        document.querySelector('.scrolling-tabs-left').classList.add('hide')
      } else {
        document.querySelector('.scrolling-tabs-left').classList.remove('hide')
      }

      if(div.scrollWidth - div.clientWidth === div.scrollLeft) {
        document.querySelector('.scrolling-tabs-right').classList.add('hide')
      } else {
        document.querySelector('.scrolling-tabs-right').classList.remove('hide')
      }
    },

    scrollTabsLeft() {
      let div = document.querySelector('.content-tabs--scrolling')
      div.scrollTo({
        left: div.scrollLeft - 300,
        behavior: 'smooth',
      })
      setTimeout(() => {this.checkVisibleScrollBtn()}, 400)
    },

    scrollTabsRight() {
      let div = document.querySelector('.content-tabs--scrolling')
      div.scrollTo({
        left: div.scrollLeft + 300,
        behavior: 'smooth',
      })
      setTimeout(() => {this.checkVisibleScrollBtn()}, 400)
    },

    onResize() {
      this.checkHasScrolling()
    }
  },

}