<template>
  <div>
    <TooltipTextHelper :fullTextWidth="300">
      <template slot="text">
        <div v-if="type = getOrderType(instance['fulfillment'], instance['return'])">
          <LabelCircle
                  v-if="type.value === ORDER_INBOUND_TYPE.FBM.value && !type.return"

                  :color="'blue'" :value="'S'" class="mr-8"/>
          <LabelCircle
                  v-if="type.value === ORDER_INBOUND_TYPE.FBA.value && !type.return"
                  :color="'gray'" :value="'A'" class="mr-8"/>
          <LabelCircle v-if="type.return"
                       :color="'red'" :value="'R'" class="mr-8"/>
        </div>
      </template>
      <template slot="paragraph">
        <div class="status-tooltip">
          <template  v-if="type.value === ORDER_INBOUND_TYPE.FBM.value && !type.return">
            <LabelCircle :color="'blue'" :value="'S'" class="mr-8"/>
            {{ $t('ordersInbound_fbmOrder.localization_value.value') }}
          </template>
          <template   v-if="type.value === ORDER_INBOUND_TYPE.FBA.value && !type.return">
            <LabelCircle :color="'gray'" :value="'A'" class="mr-8"/>
            {{ $t('ordersInbound_fbaOrder.localization_value.value') }}
          </template>
          <template v-if="type.return">
            <LabelCircle :color="'red'" :value="'R'" class="mr-8"/>
            {{ $t('ordersInbound_returnLabel.localization_value.value') }}
          </template>
        </div>
      </template>
    </TooltipTextHelper>
  </div>
</template>

<script>
  import LabelCircle from "../../../../../coreComponents/LabelCircle/LabelCircle";
  import TooltipTextHelper from "../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {ORDER_INBOUND_TYPE} from "../../../../../../staticData/staticVariables";
  import {ordersInboundMixin} from "../../../../../../mixins/ordersInbound/ordersInboundMixin";
  export default {
    name: "InboundTableTypeBlock",
    components: {TooltipTextHelper, LabelCircle},

    mixins: [ordersInboundMixin],

    props: {
      instance: Object,
    },

    data(){
      return {
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,
      }
    }
  }
</script>

<style scoped>

</style>
