<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup medium-size invoices-print"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                          'invoices_beingGenerated',
                        ])"></div>
        {{ $t('invoices_beingGenerated.localization_value.value') }}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content" style="height: 70px; display: flex; justify-content: center; align-items: flex-start;">
        <DotsShadowPreloader/>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/commonModals/Modal";
import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
export default {
  name: "DownloadingPopup",
  components: {
    DotsShadowPreloader,
    Modal
  },

  props: {

  },

  data() {
    return {

    }
  },

  mounted() {


  },

  methods: {

  },

}
</script>

<style scoped>

</style>