
export function VatNumber() {

  this.data = {
    vatNumber: '',
    visibleVatNumber: true,
    isVatNumberByCountry: false,
  }

  /**
   * Getters
   */
  this.getVatNumber = () => {
    return this.vatNumber
  }
  this.getVisibleVatNumber = () => {
    return this.visibleVatNumber
  }
  this.getIsVatNumberByCountry = () => {
    return this.isVatNumberByCountry
  }

  /**
   * Setters
   */
  this.setVatNumber = (val) => {
    this.vatNumber = val
  }
  this.setVisibleVatNumber = (val) => {
    this.visibleVatNumber = val
  }
  this.setIsVatNumberByCountry = (val) => {
    this.isVatNumberByCountry = val
  }


  this.validation = {
    vatNumber: false,
    visibleVatNumber: false,
    isVatNumberByCountry: false,
  }

  this.validationTranslate = {
    vatNumber: '',
    visibleVatNumber: '',
    isVatNumberByCountry: '',
  }

  this.validationTxt = {
    vatNumber: false,
    visibleVatNumber: false,
    isVatNumberByCountry: false,
  }

}
