<template>
  <div class="fragment">
    <InvoicesTableHead
            :tabs="navTabs"
            :showFilter="showFilter"
            :navTabs="navTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeTab="(data) => $emit('changeTab', data)"
            @reload="$emit('reload')"
            @selectAll="selectAll"
            @printInvoice="(dateInvoice) => $emit('printInvoice', {date: dateInvoice, selected: true})"
    />
            <!--@changeTab="(item) => $emit('changeTab', item)"-->
    <div class="table-filter-wrap">

      <InvoicesTableFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              :navTabs="navTabs"
              @close="closeFilter"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />

      <InvoicesTableBlock
              :navTabs="navTabs"
              :countFilterParams="countFilterParams"
              :selectedNow="selectedNow"
              :sortParams="sortParams"
              @changeSort="data => $emit('changeSort', data)"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @reload="$emit('reload')"
              @printInvoice="(data) => $emit('printInvoice', data)"
              @refundInvoice="(data) => $emit('refundInvoice', data)"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>


  </div>
</template>

<script>
  import InvoicesTableHead from "./InvoicesTableHead/InvoicesTableHead";
  import InvoicesTableFilter from "./InvoicesTableFilter/InvoicesTableFilter";
  import InvoicesTableBlock from "./InvoicesTableBlock/InvoicesTableBlock.vue";


  export default {
    name: "InvoicesTable",
    components: {
      InvoicesTableHead,
      InvoicesTableFilter,
      InvoicesTableBlock,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
      sortParams: Object,
    },

    data() {
      return {
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },

    }
  }
</script>

<style scoped>

</style>
