<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
                class="mt-1"
                :selectedNow="selectedNow"
                :dataValue="item.id"
        />

        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInvoice')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_ID.localization_value.value') }}
            </div>
            <div class="table-card__item-info table-link btn-style cursor-pointer"
                 @click="$emit('showInvoice')"
            >
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                      :item="item"
                      :mobile="true"
              />
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Consolidation.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.expressOrderShow + '/' +
												getConsolidationType(item.invoice_instance.instance) + '/' + item.invoice_instance.instance.id"
                >
                  {{item.invoice_instance.instance.id}}
                </router-link>

              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_Payment.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <div class="d-flex align-items-center" >
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="item.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>

              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_trackNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <TooltipTextHelper
                    :fullTextWidth="220"
                    :text="item.invoice_instance.instance.user_tracking_number"
                    :paragraph="item.invoice_instance.instance.user_tracking_number"
                />
              </template>
            </div>
          </div>
<!--          <div class="table-card__item-col custom-col">-->
<!--            <div class="table-card__item-label">-->
<!--              {{ $t('invoices_Payment.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--                  'common_Paid',-->
<!--                  'invoices_AccountBalance',-->
<!--                ])"></div>-->
<!--              <v-popover-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top"-->
<!--                      trigger="hover"-->
<!--              >-->
<!--                <StatusIcoBtn :type="'active'" class="mr-1"/>-->
<!--                <template slot="popover">-->
<!--                  <div class="status-tooltip">-->
<!--                    <StatusIcoBtn :type="'active'" class="mr-1"/>-->
<!--                    {{$t('common_Paid.localization_value.value')}}-->
<!--                  </div>-->
<!--                </template>-->
<!--              </v-popover>-->
<!--              {{$t('invoices_AccountBalance.localization_value.value')}}-->
<!--            </div>-->
<!--          </div>-->
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_Processing.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="+item.fee_amount > 0"
                  :value="item.fee_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Shipping.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="+item.net_cost_amount > 0"
                  :value="item.net_cost_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Packaging.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="+item.packaging_amount > 0"
                  :value="item"
                  :deep="'packaging_amount'"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </div>
          </div>
<!--          <div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
<!--            <div class="table-card__item-label">-->
<!--              {{ $t('invoices_Insurance.localization_value.value') }}-->
<!--            </div>-->
<!--            <div class="table-card__item-info">-->
<!--              <ValueHelper-->
<!--                      :value="item.insurance_amount"-->
<!--                      :replaceValue="0"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--Order, $-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--456-->
            <!--</div>-->
          <!--</div>-->
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Total.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  v-if="+item.total_amount > 0"
                  :value="item.total_amount"
                  :replaceValue="'—'"
              />
              <template v-else>—</template>
            </div>
          </div>
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate([
                    'mypayments_printInvoice',
                  ])"></div>
            <LinkButton
                    :value="$t('mypayments_printInvoice.localization_value.value')"
                    :type="'print'"
                    :datePicker="true"
                    :popup-class="'mobile-center'"
                    :datePickerRange="false"
                    :datePickerTxt="'Print Invoice'"
                    :datePickerFormat="'YYYY-MM-DD'"
                    @changeDate="(val) => $emit('changeDate', val)"
                    @datePickerBtn="$emit('printInvoice')"
            />
          </div>
          <div
              class="table-card__item-btn-i"
              v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
          >
            <LinkButton

                :value="$t(`invoices_refund.localization_value.value`)"
                :type="'return'"
                @click.native="$emit('refundInvoice', item.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import LabelCircle from "../../../../../../../../coreComponents/LabelCircle/LabelCircle";
  // import ManagerButton from "../../../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {consolidationMixin} from "../../../../../../../../../mixins/consolidationMixins/consolidationMixin";
  import TableUserColumn from "../../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../../staticData/staticVariables";
  import TooltipTextHelper from "../../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "../../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";

  export default {
    name: "InvoicesConsolidationsTableMobile",

    components: {
      StatusIcoBtn,
      TooltipTextHelper,
      TableUserColumn,
      ValueHelper,
      // ManagerButton,
      // LabelCircle,
      DefaultCheckbox,
      LinkButton
    },

    mixins: [consolidationMixin],

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,
      }
    },


  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
