<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
        <DefaultCheckbox
                class="mt-1"
                :selectedNow="selectedNow"
                :dataValue="item.id"
        />

        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInvoice')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_ID.localization_value.value') }}
            </div>
            <div class="table-card__item-info table-link btn-style"
                 @click="$emit('showInvoice')"
            >
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Date.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-info">
            <div class="table-card__item-col custom-col" v-if="isAdmin">
              <div class="table-card__item-label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="table-card__item-info">
                <TableUserColumn
                        :item="item"
                        :mobile="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_OrderNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <router-link
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.novaPoshta + '/show/' + item.invoice_instance.instance.id"
                >
                  {{item.invoice_instance.instance.id}}
                </router-link>

              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_TrackParcelNumber.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.invoice_instance && item.invoice_instance.instance">
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="table-row ellipsis">
                    <ValueHelper :value="item.invoice_instance.instance.tracking_number"/>
                  </div>
                  <template slot="popover">
                    <p><ValueHelper :value="item.invoice_instance.instance.tracking_number"/></p>
                  </template>
                </v-popover>
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{ $t('invoices_Payment.localization_value.value') }}
            </div>
            <div class="table-card__item-info"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                  'common_Paid',
                  'invoices_AccountBalance',
                ])"></div>
              <TooltipTextHelper :fullTextWidth="300">
                <template slot="text">
                  <template v-if="item.status === 'payed'">
                    <StatusIcoBtn :type="'active'" class="mr-1 mt-1"/>  {{$t('common_Paid.localization_value.value')}}
                  </template>
                  <template v-else>
                    <StatusIcoBtn :type="'cancel'" class="mr-1 mt-1"/>  {{$t('status_Canceled.localization_value.value')}}
                  </template>
                </template>
                <template slot="paragraph">
                  <div class="status-tooltip">
                    <template v-if="item.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </div>
                </template>
              </TooltipTextHelper>
            </div>
          </div>
          <!--<div class="table-card__item-col custom-col">-->
            <!--<div class="table-card__item-label">-->
              <!--{{ $t('invoices_Processing.localization_value.value') }}-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--<ValueHelper-->
                      <!--:value="item.fee_amount"-->
                      <!--:replaceValue="0"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{ $t('invoices_Shipping.localization_value.value') }}
            </div>
            <div class="table-card__item-info">
              {{item.total_amount}}
            </div>
          </div>

          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--Packaging, $-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--456-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--{{ $t('invoices_Insurance.localization_value.value') }}-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--<ValueHelper-->
                      <!--:value="item.insurance_amount"-->
                      <!--:replaceValue="0"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--Order, $-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--456-->
            <!--</div>-->
          <!--</div>-->

          <!--<div class="table-card__item-col custom-col custom-col&#45;&#45;50">-->
            <!--<div class="table-card__item-label">-->
              <!--{{ $t('invoices_Total.localization_value.value') }}-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--<ValueHelper-->
                      <!--:value="item.total_amount"-->
                      <!--:replaceValue="0"-->
              <!--/>-->
            <!--</div>-->
          <!--</div>-->
        </div>


        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit"
                 @click="editTranslate([
                    'mypayments_printInvoice',
                  ])"></div>
            <LinkButton
                    :value="$t('mypayments_printInvoice.localization_value.value')"
                    :type="'print'"
                    :datePicker="true"
                    :popup-class="'mobile-center'"
                    :datePickerRange="false"
                    :datePickerTxt="'Print Invoice'"
                    :datePickerFormat="'YYYY-MM-DD'"
                    @changeDate="(val) => $emit('changeDate', val)"
                    @datePickerBtn="$emit('printInvoice')"
            />
          </div>
          <div
              class="table-card__item-btn-i"
              v-if="isAdmin && item.status === INVOICES_STATUS_PAYED.value"
          >
            <LinkButton

                :value="$t(`invoices_refund.localization_value.value`)"
                :type="'return'"
                @click.native="$emit('refundInvoice', item.id)"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import DefaultCheckbox from "../../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import ValueHelper from "../../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {expressMixins} from "../../../../../../../../../mixins/expressMixins/expressMixin";
  import TableUserColumn from "../../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {INVOICES_STATUS_PAYED} from "../../../../../../../../../staticData/staticVariables";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "InvoicesNovaPoshtaTableMobile",

    components: {
      TooltipTextHelper,
      TableUserColumn,
      ValueHelper,
      DefaultCheckbox,
      StatusIcoBtn,
      LinkButton
    },

    mixins: [expressMixins],

    props: {
      item: Object,
      selectedNow: Boolean,
    },

    data() {
      return {
        show: false,
        INVOICES_STATUS_PAYED: INVOICES_STATUS_PAYED,
      }
    },


  }
</script>

<style scoped>
.table-card__item-content .ellipsis {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}
</style>
