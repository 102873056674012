<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['invoices_ID'])"></div>
        <DefaultInput
            :label="$t('invoices_ID.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['invoices_Date'])"></span>
        <DatePickerDefault
                :label="$t('invoices_Date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-if="!navTabs.other.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['invoices_OrderNumber'])"></div>
        <DefaultInput
            :label="$t('invoices_OrderNumber.localization_value.value')"
            :type="'text'"
            v-model="orderNumber"
        />
      </div>
      <div class="table-filter__item"
           v-if="!navTabs.FBA.active && !navTabs.other.active && !navTabs.meest.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['invoices_TrackParcelNumber'])"></div>
        <DefaultInput
            :label="$t('invoices_TrackParcelNumber.localization_value.value')"
            :type="'text'"
            v-model="trackParcel"
        />
      </div>
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['shop_user'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_Payment.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="payment"-->
        <!--/>-->
      <!--</div>-->
<!--      <div class="table-filter__item"-->
<!--           v-if="!navTabs.novaPoshta.active && !navTabs.DHLFedExTNT.active"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['invoices_Payment'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('invoices_Payment.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="processing"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-if="!navTabs.DHLFedExTNT.active"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['invoices_Shipping'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('invoices_Shipping.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="shipping"-->
<!--        />-->
<!--      </div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['invoices_Packaging'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_Packaging.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="packaging"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['invoices_Insurance'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_Insurance.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="insurance"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['invoices_Order'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_Order.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="order"-->
        <!--/>-->
      <!--</div>-->
      <div class="table-filter__item"
           v-if="!navTabs.novaPoshta.active && !navTabs.meest.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['invoices_Total'])"></div>
        <DefaultInput
            :label="$t('invoices_Total.localization_value.value')"
            :type="'text'"
            v-model="total"
        />
      </div>
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['fBAStatus'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="'fBAStatus'"-->
            <!--:type="'text'"-->
            <!--v-model="fBAStatus"-->
        <!--/>-->
      <!--</div>-->

      <!--             TODO  FILTERS                        -->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['invoices_CostSet'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_CostSet.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="costSet"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['invoices_Type'])"></div>-->
        <!--<DefaultInput-->
            <!--:label="$t('invoices_Type.localization_value.value')"-->
            <!--:type="'text'"-->
            <!--v-model="otherType"-->
        <!--/>-->
      <!--</div>-->

      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['shop_user'])"></div>
        <SearchSelect
                :options="options"
                :label="$t('shop_user.localization_value.value')"
                :functionSearch="functionSearchUserFilter"
                @change="changeUsersFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
        />
      </div>
      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin' && !navTabs.meest.active"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['shop_user'])"></div>
        <DefaultSelect
            :options="optionsStatus"
            @change="changeInvoicesStatus"
            :label="$t('invoices_Payment.localization_value.value')"
            :otherValue="'translation'"
            :selected="status === '' ? null : _.find(optionsStatus, {value: status})"
        />
      </div>

      <div class="table-filter__item"
           v-if="navTabs.orders.active && $store.getters.getInvoicesCommonList.send_from_list && $store.getters.getInvoicesCommonList.send_from_list.data && $store.getters.getInvoicesCommonList.send_from_list.data.length"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_SendFromInformation'])"></div>
        <DefaultSelect
            :options="$store.getters.getInvoicesCommonList.send_from_list.data"
            :label="$t('fbm_SendFromInformation.localization_value.value')"
            :optionsLabel="'full_name'"
            :selected="status === '' ? null : _.find($store.getters.getInvoicesCommonList.send_from_list.data, {company_name: sendFrom})"
            @change="changeSendFrom"
        />
      </div>
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import {INVOICES_ARRAY_STATUSES_FILTER} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "InvoicesTableFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DefaultInput,
      DefaultSelect,
      DatePicker,
      DatePickerDefault,
    },

    props: {
      navTabs: Object,
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        recepientName: '',
        recepientEmail: '',
        storeName: '',
        totalAmount: '',
        item: '',
        zipCode: '',
        user: '',
        payPal: '',
        trakingNumber: '',


        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',

        orderNumber: this.filterGetParams.orderNumber ? this.filterGetParams.orderNumber : '',
        trackParcel: this.filterGetParams.trackParcel ? this.filterGetParams.trackParcel : '',
        payment: this.filterGetParams.payment ? this.filterGetParams.payment : '',
        processing: this.filterGetParams.processing ? this.filterGetParams.processing : '',
        shipping: this.filterGetParams.shipping ? this.filterGetParams.shipping : '',
        packaging: this.filterGetParams.packaging ? this.filterGetParams.packaging : '',
        insurance: this.filterGetParams.insurance ? this.filterGetParams.insurance : '',
        order: this.filterGetParams.order ? this.filterGetParams.order : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        fBAStatus: this.filterGetParams.fBAStatus ? this.filterGetParams.fBAStatus : '',
        costSet: this.filterGetParams.costSet ? this.filterGetParams.costSet : '',
        otherType: this.filterGetParams.otherType ? this.filterGetParams.otherType : '',
        sendFrom: this.filterGetParams.sendFrom ? this.filterGetParams.sendFrom : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        options: [],
        optionsStatus: INVOICES_ARRAY_STATUSES_FILTER,
        optionsCountries: [],

        filterCounts: [
          'date',
          'id',
          'orderNumber',
          'trackParcel',
          'payment',
          'processing',
          'shipping',
          'packaging',
          'insurance',
          'order',
          'total',
          'status',
          'fBAStatus',
          'costSet',
          'otherType',
          'sendFrom',

          'userName',
          'userId',
        ],

      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.id = newVal.id ? newVal.id : ''
        this.orderNumber = newVal.orderNumber ? newVal.orderNumber : ''
        this.trackParcel = newVal.trackParcel ? newVal.trackParcel : ''
        this.payment = newVal.payment ? newVal.payment : ''
        this.processing = newVal.processing ? newVal.processing : ''
        this.shipping = newVal.shipping ? newVal.shipping : ''
        this.packaging = newVal.packaging ? newVal.packaging : ''
        this.insurance = newVal.insurance ? newVal.insurance : ''
        this.order = newVal.order ? newVal.order : ''
        this.total = newVal.total ? newVal.total : ''
        this.status = newVal.status ? newVal.status : ''
        this.fBAStatus = newVal.fBAStatus ? newVal.fBAStatus : ''
        this.costSet = newVal.costSet ? newVal.costSet : ''
        this.otherType = newVal.otherType ? newVal.otherType : ''
        this.sendFrom = newVal.sendFrom ? newVal.sendFrom : ''

        this.userName = newVal.userName ? newVal.userName : ''
        this.userId = newVal.userId ? newVal.userId : ''
      },

    },

    mounted() {

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },


      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeInvoicesStatus(val) {
        if (val === '') {
          this.status = ''
        } else {
          this.status = val.value
        }

      },

      changeSendFrom(val) {
        if (val === '') {
          this.sendFrom = ''
        } else {
          this.sendFrom = val.company_name
        }

      },


    },

  }
</script>

<style scoped>

</style>
