<template>
  <modal
          @close="$emit('close')"
          class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{invoice.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'common_orderDetails',
          'invoices_Date',
          'invoices_OrderNumber',
          'invoices_Consolidation',
          'invoices_TrackParcelNumber',
          'invoices_trackNumber',
          'invoices_Payment',
          'invoices_Processing',
          'invoices_Shipping',
          'invoices_Insurance',
          'invoices_Total',
        ])"></span>

        <div class="transaction-info__name" v-if="getIsAdminRights === 'admin' && invoice.user">
          {{invoice.user.user_personal_contact.user_full_name}}
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('common_orderDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('invoices_Date.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{invoice.created_at | moment("DD MMM, YYYY")}}
              </div>
            </div>
            <div v-if="typeInvoice !== 'custom'" class="transaction-info__col">
              <div v-if="typeInvoice !== 'consolidation'" class="transaction-info__label">
                {{$t('invoices_OrderNumber.localization_value.value')}}
              </div>
              <div v-else class="transaction-info__label">
                {{$t('invoices_Consolidation.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <router-link
                        v-if="typeInvoice === 'novaPoshta'"
                        class="table-link btn-style"
                        :to="GET_PATHS.novaPoshta + '/show/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'cdek'"
                        class="table-link btn-style"
                        :to="GET_PATHS.cdek + '/show/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'express'"
                        class="table-link btn-style"
                        :to="GET_PATHS.expressOrderShow + '/' +
												getExpressTypeById(invoice.invoice_instance.instance.delivery_service_id).name + '/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'consolidation'"
                    class="table-link btn-style"
                    :to="GET_PATHS.ordersConsolidationDetail + '/' +
												getConsolidationTypeByDestinationId(invoice.invoice_instance.instance.consolidation_destination.id) + '/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'inbound'"
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                        getOrderType(invoice.invoice_instance.instance['fulfillment'], invoice.invoice_instance.instance['return']).value +
                        '/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'FBA'"
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <router-link
                    v-else-if="typeInvoice === 'FBM' && invoice.invoice_instance.instance"
                        class="table-link btn-style"
                        :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(invoice.invoice_instance.instance.order_type_id).name + '/' + invoice.invoice_instance.instance.id"
                >
                  {{invoice.invoice_instance.instance.id}}
                </router-link>
                <template v-else-if="typeInvoice === 'storage'">
                  <router-link
                      v-if="invoice.invoice_type_id === INVOICES_TYPES.INVOICES_TYPE_DISPOSAL.id"
                      class="table-link btn-style"
                      :to="$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + invoice.invoice_instance.instance.id"
                  >
                    {{invoice.invoice_instance.instance.id}}
                  </router-link>
                  <router-link
                      v-else-if="invoice.invoice_instance.model === 'order_inbound'"
                      class="table-link btn-style"
                      :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                        getOrderType(invoice.invoice_instance.instance['fulfillment'], invoice.invoice_instance.instance['return']).value +
                        '/' + invoice.invoice_instance.instance.id"
                  >
                    {{invoice.invoice_instance.instance.id}}
                  </router-link>
                  <template v-else>
                    {{invoice.invoice_instance.instance.id}}
                  </template>

                </template>
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col"  v-if="typeInvoice !== 'FBA' && typeInvoice !== 'storage' && typeInvoice !== 'custom' && typeInvoice !== 'consolidation'">
              <div class="transaction-info__label">
                {{$t('invoices_TrackParcelNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="invoice.invoice_instance && invoice.invoice_instance.instance">
                  <ValueHelper :value="invoice.invoice_instance.instance.tracking_number"/>
                </template>
              </div>
            </div>
            <div class="transaction-info__col"  v-if="typeInvoice === 'consolidation'">
              <div class="transaction-info__label">
                {{$t('invoices_trackNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="invoice.invoice_instance && invoice.invoice_instance.instance">
                  <ValueHelper :value="invoice.invoice_instance.instance.user_tracking_number"/>
                </template>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('invoices_Payment.localization_value.value')}}
              </div>
              <div class="d-flex align-items-center"
              >
<!--                   v-if="typeInvoice === 'inbound' || typeInvoice === 'FBA' || typeInvoice === 'storage' || typeInvoice === 'consolidation' || typeInvoice === 'express' || typeInvoice === 'custom'"-->
                <InboundTableTypeBlock
                        v-if="typeInvoice === 'inbound'"
                        :instance="invoice.invoice_instance.instance"
                />
                <TooltipTextHelper :fullTextWidth="300">
                  <template slot="text">
                    <template v-if="invoice.status === 'payed'">
                      <StatusIcoBtn :type="'active'" class="mr-1 mt-1"/>  {{$t('common_Paid.localization_value.value')}}
                    </template>
                    <template v-else>
                      <StatusIcoBtn :type="'cancel'" class="mr-1 mt-1"/>  {{$t('status_Canceled.localization_value.value')}}
                    </template>
                  </template>
                  <template slot="paragraph">
                    <div class="status-tooltip">
                      <template v-if="invoice.status === 'payed'">
                        <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}
                      </template>
                      <template v-else>
                        <StatusIcoBtn :type="'cancel'" class="mr-1"/>  {{$t('status_Canceled.localization_value.value')}}
                      </template>
                    </div>
                  </template>
                </TooltipTextHelper>
              </div>

<!--              <div class="transaction-info__txt" v-else>-->
<!--                <StatusIcoBtn :type="'active'" class="mr-1"/>  {{$t('common_Paid.localization_value.value')}}-->
<!--&lt;!&ndash;                {{$t('invoices_AccountBalance.localization_value.value')}}&ndash;&gt;-->
<!--              </div>-->
            </div>
            <div class="transaction-info__col "
                 v-if="typeInvoice === 'express' || typeInvoice === 'consolidation'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Processing.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="invoice.fee_amount"
                />
<!--                        :replaceValue="0"-->
              </div>
            </div>
            <div class="transaction-info__col"
                 v-if="typeInvoice === 'storage' || typeInvoice === 'custom'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Comment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    class="word-break"
                    :deep="'comment'"
                />
              </div>
            </div>
            <div class="transaction-info__col " v-if="typeInvoice !== 'FBA' && typeInvoice !== 'storage'">
              <div class="transaction-info__label">
                {{$t('invoices_Shipping.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    v-if="(typeInvoice === 'express' || typeInvoice === 'consolidation') && invoice.net_cost_amount > 0"
                    :value="invoice.net_cost_amount"
                />
                <span v-else-if="typeInvoice === 'novaPoshta'">{{invoice.total_amount}}</span>
                <span v-else-if="typeInvoice === 'cdek'">{{invoice.total_amount}}</span>
                <span v-else-if="typeInvoice === 'inbound'">
                  <ValueHelper
                      :value="invoice.invoice_instance.instance.shipping_price"
                      :costType="true"
                      :costCurrencyType="'dollar'"
                  />
                </span>
                <ValueHelper
                    v-else-if="typeInvoice === 'custom' || typeInvoice === 'FBM'"
                    :value="invoice.net_cost_amount"
                />

                <span v-else>—</span>

              </div>
            </div>
<!--            <div class="transaction-info__col "-->
<!--                 v-if="typeInvoice === 'express'"-->
<!--            >-->
<!--              <div class="transaction-info__label">-->
<!--                {{$t('invoices_Insurance.localization_value.value')}}-->
<!--              </div>-->
<!--              <div class="transaction-info__txt">-->
<!--                <ValueHelper-->
<!--                        :value="invoice.insurance_amount"-->
<!--                        :replaceValue="0"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            <div class="transaction-info__col "
                 v-if="typeInvoice === 'inbound' || typeInvoice === 'custom' || typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Order.localization_value.value')}}
              </div>
              <div v-if="typeInvoice === 'inbound'" class="transaction-info__txt">
                <ValueHelper
                    :value="invoice.invoice_instance.instance.order_process_price"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
              <div v-if="typeInvoice === 'custom' || typeInvoice === 'FBM'" class="transaction-info__txt">
                <ValueHelper
                    :value="invoice.order_price_amount"
                />
              </div>
            </div>
            <div class="transaction-info__col "
                 v-if="typeInvoice === 'inbound' || typeInvoice === 'FBA' || typeInvoice === 'FBM' || typeInvoice === 'custom' || typeInvoice === 'consolidation'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Packaging.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="typeInvoice === 'inbound' || typeInvoice === 'custom' || typeInvoice === 'consolidation' || typeInvoice === 'FBM'">
                  <ValueHelper
                          :value="invoice"
                          :deep="'packaging_amount'"
                          :costType="true"
                  />
                </template>
                <template v-if="typeInvoice === 'FBA'">
                  <ValueHelper
                          :value="invoice"
                          :deep="'invoice_instance.instance.packing_cost'"
                  />
                </template>
              </div>
            </div>
            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBA'"
            >
              <div class="transaction-info__label">
                {{$t('fba_skladUsaFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{invoice.fee_amount}}
              </div>
            </div>
            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBA'"
            >
              <div class="transaction-info__label">
                {{ $t('invoices_CostSet.localization_value.value') }}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'net_cost_amount'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'storage' || typeInvoice === 'custom'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Storage.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'storage_cost_amount'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('fbm_GiftMessage.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'gift_message_amount'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('fba_skladUsaFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'invoice_instance.instance.order_admin_fee'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_fbmSkladUSAInsurance.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'summary_data.insurance_sklad_usa'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_fbmInsuranceEasyPost.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice"
                    :deep="'summary_data.insurance_from_delivery_system'"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
            </div>

            <div class="transaction-info__col "
                 v-if="typeInvoice === 'express' || typeInvoice === 'inbound' || typeInvoice === 'FBA' || typeInvoice === 'storage' || typeInvoice === 'custom'
                 || typeInvoice === 'consolidation' || typeInvoice === 'FBM'"
            >
              <div class="transaction-info__label">
                {{$t('invoices_Total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="invoice.total_amount"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import {mapGetters} from "vuex";
  import {expressMixins} from "../../../../../mixins/expressMixins/expressMixin";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {INVOICES_TYPES, ORDER_INBOUND_TYPE} from "../../../../../staticData/staticVariables";
  import {ordersInboundMixin} from "../../../../../mixins/ordersInbound/ordersInboundMixin";
  import InboundTableTypeBlock
    from "../../../OrdersInboundModule/components/components/InboundTableTypeBlock/InboundTableTypeBlock";
  import TooltipTextHelper from "../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
  import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";


  export default {
    name: "InfoPopup",

    components: {
      StatusIcoBtn,
      TooltipTextHelper,
      InboundTableTypeBlock,
      ValueHelper,
      Modal,
    },

    mixins: [expressMixins, ordersInboundMixin,consolidationMixin, FBMMixinsHelper],

    props: {
      item: Object,
      typeInvoice: {
        type: String,
        default: null
      },
    },


    computed: {
      ...mapGetters({
        invoice: 'getInvoicesItem',
        getUserProfile: 'getUserProfile',
        getIsAdminRights: 'getIsAdminRights',
        GET_PATHS: 'GET_PATHS',
      })
    },

    data() {
      return {
        ORDER_INBOUND_TYPE: ORDER_INBOUND_TYPE,

        INVOICES_TYPES: INVOICES_TYPES,

      }
    },

    mounted() {

    },

  }

</script>

<style lang="scss">

</style>
